/**
 * External dependencies
 */
import { useState, Fragment } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import ConfigGroup from '@jewellery-configurator/components/config-group/config-group';
import IconDiamond from '@jewellery-configurator/icons/diamond';
import CrystalRed from '@images/jewellery-configurator/crystals/circle-red.png';
import CrystalBlue from '@images/jewellery-configurator/crystals/circle-blue.png';
import CrystalYellow from '@images/jewellery-configurator/crystals/circle-yellow.png';
import CrystalGreen from '@images/jewellery-configurator/crystals/circle-green.png';
import EllipseRed from '@images/jewellery-configurator/crystals/ellipse-red.png';
import EllipseBlue from '@images/jewellery-configurator/crystals/ellipse-blue.png';
import EllipseYellow from '@images/jewellery-configurator/crystals/ellipse-yellow.png';
import EllipseGreen from '@images/jewellery-configurator/crystals/ellipse-green.png';

const CRYSTALS = [
    {
        type: 'red',
        label: 'Червен',
        imageSrc: CrystalRed,
    },
    {
        type: 'blue',
        label: 'Син',
        imageSrc: CrystalBlue,
    },
    {
        type: 'yellow',
        label: 'Жълт',
        imageSrc: CrystalYellow,
    },
    {
        type: 'green',
        label: 'Зелен',
        imageSrc: CrystalGreen,
    },
    {
        type: 'red-ellipse',
        label: 'Червен',
        imageSrc: EllipseRed,
    },
    {
        type: 'blue-ellipse',
        label: 'Син',
        imageSrc: EllipseBlue,
    },
    {
        type: 'yellow-ellipse',
        label: 'Жълт',
        imageSrc: EllipseYellow,
    },
    {
        type: 'green-ellipse',
        label: 'Зелен',
        imageSrc: EllipseGreen,
    },
];

const ConfigJewelleryCrystals = (props) => {
    const { onDragStart, onDragStop, isOpened, open, close, toggle } = props;

    const [selectedCrystalCategory, selectCrystalCategory] = useState();

    const crystals = window.$$jewelleryConfigurator.crystals;
    const crystalCategories = window.$$jewelleryConfigurator.crystalCategories;

    const filteredCrystals = !!selectedCrystalCategory
        ? crystals.filter((crystal) => {
            return crystal.categories.map((cat) => cat.term_id).includes(selectedCrystalCategory.term_id);
        })
        : crystals;

    return (
        <Fragment>
            {isOpened && createPortal(
                <ConfigGroup
                    plain
                    label="Форма на Кристала"
                    size="auto"
                    isOpened={true}
                >
                    <div className="jc-jewellery-types per-row--2">
                        {crystalCategories.map((crystalCategory) => {
                            return (
                                <div
                                    key={crystalCategory.term_id}
                                    className={classNames('jc-jewellery-type jc-jewellery-type--plain', {
                                        selected: selectedCrystalCategory?.term_id == crystalCategory.term_id
                                    })}
                                    onClick={() => {
                                        if (crystalCategory.term_id == selectedCrystalCategory?.term_id) {
                                            selectCrystalCategory(null);
                                        } else {
                                            selectCrystalCategory(crystalCategory)
                                        }
                                    }}
                                >
                                    <div className="jc-jewellery-type__image">
                                        <img src={crystalCategory.thumbnail} />
                                    </div>

                                    <div className="jc-jewellery-type__label">{crystalCategory.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </ConfigGroup>,
                document.getElementById('jc-configurator__sidebar--left')
            )}

            <ConfigGroup
                label="Кристали"
                icon={IconDiamond}
                size="small"
                isOpened={isOpened}
                onToggle={toggle}
            >
                <div className="jc-jewellery-types per-row--3">
                    {filteredCrystals.map((type) => {
                        return (
                            <div
                                className={classNames('jc-jewellery-type')}
                                key={type.id}
                                draggable={true}
                                onDragStart={(event) => {
                                    event.dataTransfer.setData('src', type.thumbnail);
                                    event.dataTransfer.setData('width', type.width);
                                    event.dataTransfer.setData('height', type.height);
                                    event.dataTransfer.setData('id', type.id);
                                    event.dataTransfer.setData('price', type.price);
                                    event.dataTransfer.setData('title', type.title);
                                    event.dataTransfer.setData('contents', type.contents);
                                    onDragStart();
                                }}
                                onDragEnd={onDragStop}
                            >
                                {type.thumbnail.includes('svg') ? (
                                    <div
                                        className="jc-jewellery-type__image"
                                        dangerouslySetInnerHTML={{ __html: type.contents }}
                                    />
                                ) : (
                                    <div className="jc-jewellery-type__image">
                                        <img src={type.thumbnail} width={type.width} height={type.height} />
                                    </div>
                                )}

                                <div className="jc-jewellery-type__label">{type.title}</div>
                            </div>
                        );
                    })}
                </div>
            </ConfigGroup>
        </Fragment>
    );
};

export default ConfigJewelleryCrystals;
