/**
 * External dependencies
 */
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';

/**
 * Internal dependencies
 */
import Root from '@jewellery-configurator/root.jsx';

/**
 * Initialize app.
 */
const rootElement = document.getElementById('jewellery-configurator');

if (rootElement) {
    const root = createRoot(rootElement);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
            },
        },
    });

    root.render(
        <QueryClientProvider client={queryClient}>
            <Root />
        </QueryClientProvider>
    );
}
