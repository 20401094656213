/**
 * External dependencies
 */
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

/**
 * Internal dependencies
 */
import CanvasCrystal from '@jewellery-configurator/canvas/components/canvas-crystal/canvas-crystal';
import { insertCrystal, selectCrystal, clearSelectedCrystal } from '@jewellery-configurator/store/actions';
import { useCrystals, useSelectedCrystal } from '@jewellery-configurator/store/selectors';

const Canvas = (props) => {
    const { isDragging } = props;

    const canvasRef = useRef();

    const crystals = useCrystals();
    const selectedCrystalId = useSelectedCrystal();

    useEffect(() => {
        const handleMouseDown = (event) => {
            // Only handle clicks on the element, not the children.
            if (event.target !== canvasRef.current) {
                return;
            }

            // There is no selected crystal, halt.
            if (!selectedCrystalId) {
                return;
            }

            clearSelectedCrystal();
        };

        canvasRef.current.addEventListener('mousedown', handleMouseDown);

        return () => canvasRef.current?.removeEventListener('mousedown', handleMouseDown);
    }, [canvasRef, selectedCrystalId]);

    return (
        <div
            ref={canvasRef}
            id="jc-canvas"
            className={classNames('jc-canvas', isDragging && 'is-dragging')}
            onDragOver={(event) => {
                event.preventDefault();
            }}
            onDrop={(event) => {
                event.preventDefault();

                if (!event.dataTransfer.getData('id')) {
                    return;
                }

                const itemSrc = event.dataTransfer.getData('src');
                const itemId = event.dataTransfer.getData('id');
                const itemWidth = event.dataTransfer.getData('width');
                const itemHeight = event.dataTransfer.getData('height');
                const itemPrice = event.dataTransfer.getData('price');
                const itemTitle = event.dataTransfer.getData('title');
                const itemContents = event.dataTransfer.getData('contents');

                const id = nanoid();

                insertCrystal({
                    id: id,
                    title: itemTitle,
                    productId: itemId,
                    price: itemPrice,
                    src: itemSrc,
                    contents: itemContents,
                    width: itemWidth,
                    height: itemHeight,
                    left: event.clientX - canvasRef.current.getBoundingClientRect().left,
                    top: event.clientY - canvasRef.current.getBoundingClientRect().top,
                    rotation: 0,
                });

                selectCrystal(id);
            }}
        >
            {crystals.map((crystal) => (
                <CanvasCrystal crystal={crystal} key={crystal.id} />
            ))}
        </div>
    );
};

export default Canvas;
