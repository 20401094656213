/**
 * External dependencies
 */
import { useMutation } from 'react-query';

/**
 * Internal dependencies
 */
import http, { toFormData } from '@jewellery-configurator/data/http';

const useAddToCartMutation = (options) => {
    return useMutation(
        (data) => {
            return http.post(
                window.$$context.url,
                toFormData({
                    nonce: window.$$context.add_to_cart_nonce,
                    action: 'app_add_to_cart',
                    ...data,
                })
            );
        },
        {
            ...options,
        }
    );
};

export default useAddToCartMutation;
