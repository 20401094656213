$(document).on("click", ".js-quantity", function (e) {
	e.preventDefault();

	const $trigger = $(this);
	const $quantityInput = $trigger.parent().find("input");

	if ($trigger.hasClass("quantity-plus")) {
		$quantityInput.val(parseInt($quantityInput.val()) + 1);

		$quantityInput.trigger('change');
	}

	if ($trigger.hasClass("quantity-minus")) {
		if (parseInt($quantityInput.val()) === 1) {
			return;
		}

		$quantityInput.val(parseInt($quantityInput.val()) - 1);
		$quantityInput.trigger('change');
	}
});
