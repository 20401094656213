/**
 * External dependencies
 */
import { useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import Canvas from '@jewellery-configurator/canvas/components/canvas/canvas';
import ConfigJewelleryElements from '@jewellery-configurator/components/config-jewellery-elements/config-jewellery-elements';
import ConfigJewelleryCoverage from '@jewellery-configurator/components/config-jewellery-coverage/config-jewellery-coverage';
import ConfigJewelleryCrystals from '@jewellery-configurator/components/config-jewellery-crystals/config-jewellery-crystals';
import ConfigJewelleryProductType from '@jewellery-configurator/components/config-jewellery-product-type/config-jewellery-product-type';
import Summary from '@jewellery-configurator/summary/summary';
import useToggle from '@jewellery-configurator/hooks/use-toggle';
import { removeCrystal, clearSelectedCrystal } from '@jewellery-configurator/store/actions';
import { useSelectedCrystal } from '@jewellery-configurator/store/selectors';

const Configurator = () => {
    const selectedCrystalId = useSelectedCrystal();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const {
        on: isProductTypeGroupOpened,
        setOn: openProductTypeGroup,
        setOff: closeProductTypeGroup,
        toggle: toggleProductTypeGroup
    } = useToggle(true);
    const {
        on: isCrystalsGroupOpened,
        setOn: openCrystalsGroup,
        setOff: closeCrystalsGroup,
        toggle: toggleCrystalsGroup
    } = useToggle(false);
    const {
        on: isElementsGroupOpened,
        setOn: openElementsGroup,
        setOff: closeElementsGroup,
        toggle: toggleElementsGroup
    } = useToggle(false);
    const {
        on: isCoverageGroupOpened,
        setOn: openCoverageGroup,
        setOff: closeCoverageGroup,
        toggle: toggleCoverageGroup
    } = useToggle(false);
    const { on: isDragging, setOn: setIsDragging, setOff: setIsNotDragging } = useToggle(false);

    const config = window.$$jewelleryConfigurator;

    return (
        <div className="jc-configurator">
            <div className="jc-configurator__sidebar--left" id="jc-configurator__sidebar--left" />

            <div className="js-canvas__wrapper">
                {selectedCrystalId && (
                    <button
                        className="js-configurator__remove"
                        onClick={() => {
                            if (confirm('Сигурни ли сте, че искате да изтриете кристала?')) {
                                removeCrystal(selectedCrystalId);
                                clearSelectedCrystal();
                            }
                        }}
                    >
                        X Изтрий кристала
                    </button>
                )}

                <Canvas isDragging={isDragging} />
            </div>

            <div className="jc-configurator__config">
                <ConfigJewelleryProductType
                    isOpened={isProductTypeGroupOpened}
                    open={openProductTypeGroup}
                    close={() => {
                        closeProductTypeGroup();
                        openCrystalsGroup();
                    }}
                    toggle={toggleProductTypeGroup}
                    options={config.products}
                />

                <ConfigJewelleryCrystals
                    isOpened={isCrystalsGroupOpened}
                    open={openCrystalsGroup}
                    close={closeCrystalsGroup}
                    toggle={toggleCrystalsGroup}
                    onDragStart={setIsDragging}
                    onDragStop={setIsNotDragging}
                />

                <ConfigJewelleryElements
                    isOpened={isElementsGroupOpened}
                    open={openElementsGroup}
                    close={closeElementsGroup}
                    toggle={toggleElementsGroup}
                    options={config.addons.find((addon) => addon.title === 'Елементи')?.options ?? []}
                />

                <ConfigJewelleryCoverage
                    isOpened={isCoverageGroupOpened}
                    open={openCoverageGroup}
                    close={closeCoverageGroup}
                    toggle={toggleCoverageGroup}
                    options={config.addons.find((addon) => addon.title === 'Покритие')?.options ?? []}
                />

                <Summary />
            </div>
        </div>
    );
};

export default Configurator;
