/**
 * Internal dependencies
 */
import useStore from '@jewellery-configurator/store/store';

/**
 * Change the current screen.
 *
 * @param  {String} The screen name.
 * @return {void}
 */
export const changeScreen = (screen) => {
    useStore.setState((state) => {
        state.screen = screen;
    });
};

/**
 * Toggle is loading.
 *
 * @param  {Boolean} Loading state.
 * @return {void}
 */
export const toggleLoading = (isLoading) => {
    useStore.setState((state) => {
        state.isLoading = isLoading;
    });
};

/**
 * Sets the image.
 *
 * @return {void}
 */
export const setImage = (image) => {
    useStore.setState((state) => {
        state.image = image;
    });
};

/**
 * Insert a crystal.
 *
 * @return {void}
 */
export const insertCrystal = (crystalData) => {
    useStore.setState((state) => {
        state.crystals.push(crystalData);
    });
};

/**
 * Removes a crystal.
 *
 * @return {void}
 */
export const removeCrystal = (crystalId) => {
    useStore.setState((state) => {
        state.crystals = state.crystals.filter((crystal) => crystal.id !== crystalId);
    });
};

/**
 * Update a crystal.
 *
 * @return {void}
 */
export const updateCrystal = (crystalId, crystalData) => {
    useStore.setState((state) => {
        let crystalIndex = state.crystals.findIndex((crystal) => crystal.id === crystalId);

        state.crystals[crystalIndex] = {
            ...state.crystals[crystalIndex],
            ...crystalData,
        };
    });
};

/**
 * Selects a crystal.
 *
 * @return {void}
 */
export const selectCrystal = (crystalId) => {
    useStore.setState((state) => {
        state.selectedCrystal = crystalId;
    });
};

/**
 * Clear selected crystal.
 *
 * @return {void}
 */
export const clearSelectedCrystal = () => {
    useStore.setState((state) => {
        state.selectedCrystal = null;
    });
};

/**
 * Selects a product type.
 *
 * @return {void}
 */
export const selectProductType = (productType) => {
    useStore.setState((state) => {
        state.productType = productType;
    });
};

/**
 * Selects an element.
 *
 * @return {void}
 */
export const selectElement = (element) => {
    useStore.setState((state) => {
        state.element = element;
    });
};

/**
 * Selects coverage.
 *
 * @return {void}
 */
export const selectCoverage = (coverage) => {
    useStore.setState((state) => {
        state.coverage = coverage;
    });
};
