/**
 * Internal dependencies
 */
import useStore from '@jewellery-configurator/store/store';

/**
 * Get current screen.
 *
 * @return {String}
 */
export const useScreen = () => {
    return useStore((state) => {
        return state.screen;
    });
};

/**
 * Get loading state.
 *
 * @return {Boolean}
 */
export const useLoadingState = () => {
    return useStore((state) => {
        return state.isLoading;
    });
};

/**
 * Get image.
 *
 * @return {String}
 */
export const useImage = () => {
    return useStore((state) => {
        return state.image;
    });
};

/**
 * Get type.
 *
 * @return {String}
 */
export const useType = () => {
    return useStore((state) => {
        return state.type;
    });
};

/**
 * Get crystals.
 *
 * @return {String}
 */
export const useCrystals = () => {
    return useStore((state) => {
        return state.crystals;
    });
};

/**
 * Get element.
 *
 * @return {String}
 */
export const useElement = () => {
    return useStore((state) => {
        return state.element;
    });
};

/**
 * Get coverage.
 *
 * @return {String}
 */
export const useCoverage = () => {
    return useStore((state) => {
        return state.coverage;
    });
};

/**
 * Get product type.
 *
 * @return {Object}
 */
export const useProductType = () => {
    return useStore((state) => {
        return state.productType;
    });
};

/**
 * Get selected crystal.
 *
 * @return {String}
 */
export const useSelectedCrystal = () => {
    return useStore((state) => {
        return state.selectedCrystal;
    });
};

/**
 * Get total amount.
 *
 * @return {String}
 */
export const useTotal = () => {
    return useStore((state) => {
        const requiresPair = state.productType?.title === 'Обеци';

        return (
            Number(state.productType?.price * (requiresPair ? 2 : 1) ?? 0) +
            Number(state.coverage?.price ?? 0) +
            Number(state.element?.price ?? 0) +
            state.crystals.reduce((memo, crystal) => (memo += Number(crystal.price * (requiresPair ? 2 : 1))), 0)
        );
    });
};
