/**
 * External dependencies
 */
import html2canvas from 'html2canvas';
import { Fragment, useEffect, useState } from 'react';

/**
 * Internal dependencies
 */
import ScreenLoading from '@jewellery-configurator/screen/screen-loading';
import {
    useCrystals,
    useCoverage,
    useProductType,
    useElement,
    useType,
    useTotal,
    useLoadingState,
} from '@jewellery-configurator/store/selectors';
import { clearSelectedCrystal, changeScreen, setImage, toggleLoading } from '@jewellery-configurator/store/actions';
import useAddToCartMutation from '@jewellery-configurator/data/cart/use-add-to-cart-mutation';

const Summary = () => {
    const isLoading = useLoadingState();
    const type = useType();
    const crystals = useCrystals();
    const element = useElement();
    const coverage = useCoverage();
    const productType = useProductType();
    const total = useTotal();

    const addToCartMutation = useAddToCartMutation();

    const handleAddToCart = () => {
        clearSelectedCrystal();

        setTimeout(async () => {
            return new Promise(async (resolve, reject) => {
                html2canvas(document.getElementById('jc-canvas'), {
                    useCORS: true,
                    imageTimeout: 0,
                    allowTaint: true,
                }).then((canvas) => {
                    const image = canvas.toDataURL('image/jpeg', 1.0);

                    setImage(image);

                    addToCartMutation.mutate(
                        {
                            productType,
                            crystals,
                            element,
                            coverage,
                            image,
                        },
                        {
                            onSuccess() {
                                changeScreen('complete');
                                toggleLoading(false);
                                resolve();
                            },

                            onError() {
                                reject();
                            },
                        }
                    );
                });
            });
        }, 200);
    };

    if (!productType) {
        return null;
    }

    return (
        <Fragment>
            <div className="jc-summary">
                <div className="jc-summary__head">
                    <h3>{productType?.title}</h3>
                </div>

                <div className="jc-summary__details">
                    <dl>
                        <div className="jc-summary__details-row">
                            <dt>Елемент:</dt>
                            <dd>{!!element ? `${element?.label} (${element?.price}лв)` : '—'}</dd>
                        </div>

                        <div className="jc-summary__details-row">
                            <dt>Покритие:</dt>
                            <dd>{!!coverage ? `${coverage?.label} (${coverage?.price}лв)` : '—'}</dd>
                        </div>
                    </dl>
                </div>

                <div className="jc-summary__body">
                    <table>
                        <thead>
                            <tr>
                                <th align="left">ID</th>
                                <th align="left">Име</th>
                                <th align="center" width="50">
                                    Цена
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {crystals.map((crystal) => (
                                <tr key={crystal.id}>
                                    <td>{crystal.productId}</td>
                                    <td>{crystal.title}</td>
                                    <td align="center">{crystal.price}лв</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="jc-summary__actions">
                    <div className="jc-summary__actions-subtotal">
                        {total}лв
                        {productType.title === 'Обеци' && (
                            <Fragment>
                                <br /><small>Цена за чифт обеци</small>
                            </Fragment>
                        )}
                    </div>

                    <button
                        type="button"
                        onClick={(event) => {
                            toggleLoading(true);
                            handleAddToCart(event);
                        }}
                    >
                        Добави в количката
                    </button>
                </div>
            </div>

            {isLoading && <ScreenLoading />}
        </Fragment>
    );
};

export default Summary;
