/**
 * External dependencies
 */
import axios from 'axios';
import { serialize } from 'object-to-formdata';

export const toFormData = (data) =>
    serialize(data, {
        indices: true,
    });

/**
 * @type {import('axios').AxiosInstance}
 */
const client = axios.create({
    baseURL: window.location.origin,
});

client.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default client;
