import { Fancybox } from '@fancyapps/ui';

Fancybox.bind('[data-fancybox]', {
  groupAll: true,
  Images: {
    Panzoom: {
      maxScale: 3,
    },
  },
  Html: {
    videoTpl:
      '<video class="fancybox__html5video" playsinline muted loop poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn\'t support embedded videos.</video>',
  },
  Toolbar: {
    display: {
      left: ['infobar'],
      middle: ['zoomIn', 'zoomOut'],
      right: ['close'],
    },
  },
});
