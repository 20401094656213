$('.form-filter.js-prevent-submit form').on('submit', function (event) {
  event.preventDefault();
});

$('.form-filter select[name="product_cat"]').on('change', function (e) {
  window.location.href = e.target.value;
});

$('.form-filter select[name="orderby"]').on('change', function (e) {
  $(this).closest('form').trigger('submit');
});
