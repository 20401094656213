/**
 * Internal dependencies
 */
import { changeScreen } from '@jewellery-configurator/store/actions';

const ScreenIntro = () => {
    return (
        <div className="jc-intro jc-screen">
            <div className="jc-intro__inner jc-screen__entry">
                <h2>{window.$$jewelleryConfigurator.title}</h2>

                <h3>{window.$$jewelleryConfigurator.text}</h3>

                <button className="btn" onClick={() => changeScreen('setup')}>
                    Направи тук
                </button>
            </div>
        </div>
    );
};

export default ScreenIntro;
