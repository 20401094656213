/**
 * External dependencies
 */
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const useStore = create(
    immer(() => ({
        screen: 'intro', // intro, setup, loading, complete
        isLoading: false,

        type: '',
        productType: null,
        crystals: [],
        crystalCategories: [],
        element: '',
        coverage: '',
        selectedCrystal: null,
    }))
);

export default useStore;
