import { $win, $body } from '../utils/globals'

let lastScrollPosition = null;
const MOBILE_WIDTH = 1024;

$('.js-nav-trigger').on('click', function(event) {
	event.preventDefault();

	if ($win.width() < MOBILE_WIDTH) {
		const isMenuOpened = $body.is('.has-menu-opened');

		if (isMenuOpened) {
			$body.removeClass('has-menu-opened is-fixed');

			window.scrollTo({
				top: lastScrollPosition,
				left: 0,
				behavior: 'instant'
			});

			lastScrollPosition = null;
			$('.wrapper__inner').removeAttr('style');
			return
		} else {
			lastScrollPosition = $win.scrollTop();
			$body.addClass('is-fixed');
			$('.wrapper__inner').css({
				top: -lastScrollPosition
			});
		}
	}

	$body.toggleClass('has-menu-opened');
});

$('.nav .menu-item-has-children > a').on('click', function(e) {
	if ($win.width() < MOBILE_WIDTH) {
		if (!$(this).parent().hasClass('is-expanded')) {
			e.preventDefault();

			$(this)
				.next()
					.slideDown()
				.parent()
					.addClass('is-expanded')
				.siblings()
					.removeClass('is-expanded')
					.find('.sub-menu')
						.slideUp()
						.find('.is-expanded')
							.removeClass('is-expanded');
		};
	}
});

$('.nav a, .header__btn').on('click', function(e) {
	if ($win.width() < MOBILE_WIDTH) {
		if ($(this).data('target')) {
			$body.removeClass('has-menu-opened is-fixed');
		}
	}

	$body.addClass('is-scrolling');
	setTimeout(function() {
		$body.removeClass('is-scrolling');
	}, 600);
});