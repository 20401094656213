/**
 * External dependencies
 */
import { useRef, useEffect } from 'react';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import Gesturable from '@jewellery-configurator/canvas/components/gesturable/gesturable';

const CanvasCrystal = (props) => {
    const { crystal } = props;

    const isSVG = crystal.contents.includes('svg');

    const crystalRef = useRef();

    useEffect(() => {
        const svg = isSVG ? crystalRef.current.querySelector('svg') : null;

        svg?.setAttribute('width', crystal.width);
        svg?.setAttribute('height', crystal.height);
    }, []);

    return (
        <Gesturable targetRef={crystalRef} crystalId={crystal.id}>
            {isSVG ? (
                <div
                    className={classNames('js-canvas__crystal', `js-canvas__crystal-${crystal.id}`)}
                    ref={crystalRef}
                    width={`${crystal.width ?? 30}px`}
                    height={`${crystal.height ?? 30}px`}
                    style={{
                        transform: `translate(${crystal.left}px, ${crystal.top}px) rotate(${crystal.rotation}deg)`,
                    }}
                    dangerouslySetInnerHTML={{ __html: crystal.contents }}
                />
            ) : (
                <img
                    className={classNames('js-canvas__crystal', `js-canvas__crystal-${crystal.id}`)}
                    ref={crystalRef}
                    src={crystal.src}
                    data-id={crystal.id}
                    data-src={crystal.src}
                    width={crystal.width ?? 30}
                    height={crystal.height ?? 30}
                    style={{
                        transform: `translate(${crystal.left}px, ${crystal.top}px) rotate(${crystal.rotation}deg)`,
                    }}
                />
            )}
        </Gesturable>
    );
};

export default CanvasCrystal;
