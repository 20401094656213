import { $win } from '../utils/globals'
import Swiper, { Navigation } from 'swiper';

$('.js-slider-products').each(function(i,slider){
	const $slider = $(slider);

	if ($slider.find('.slider__slide')) {
		$slider.addClass('has-center-slides');
	}

	const productsSwiper = new Swiper(slider.querySelector('.swiper'), {
		modules: [Navigation],
		slidesPerView: 1,
		resistanceRatio: 0,
		spaceBetween: 20,
		centerInsufficientSlides: true,
		breakpoints: {
			575: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
			768: {
				slidesPerView: 3,
				spaceBetween: 64,
			},
			1024: {
				slidesPerView: 4,
				spaceBetween: 64,
			},
			1400: {
				slidesPerView: 5,
				spaceBetween: 64,
			}
		},
		navigation: {
			nextEl: slider.querySelector('.swiper-button-next'),
			prevEl: slider.querySelector('.swiper-button-prev'),
		},
	});
});

$(window).on('load resize', function(){
	$('.js-slider-products').each(function(){
		const $slider = $(this);
		const $imageHeight = $slider.find('.product-custom .product__image').outerHeight();
		const $arrow = $slider.find('.js-swiper-arrow');

		if ($imageHeight && $arrow.length) {
			$arrow.css({
				top: `${$imageHeight/2}px`
			})
		}
	});
});


$('.js-slider-gallery').each(function(i,slider){
	const $slider = $(slider);
	const $slides = $slider.find('.slider__slide');
	const $allVideos = $slides.find('video');
	const $thumbs = $slider.find('.slider__thumbs li');
	const $playButton = $slider.find('.btn-play');

	$playButton.each(function(){
		const $button = $(this);

		$button.on('click', function(e){
			e.preventDefault();

			if ($button.parent().hasClass('is-playing')) {
				$button.next('video')[0].pause();
			} else {
				$button.next('video')[0].play();
			}

			$button.parent().toggleClass('is-playing');
		});
	})


	const productsSwiper = new Swiper(slider.querySelector('.swiper'), {
		modules: [Navigation],
		slidesPerView: 1,
		resistanceRatio: 0,
		spaceBetween: 0,
		centerInsufficientSlides: true,
		navigation: {
			nextEl: slider.querySelector('.swiper-button-next'),
			prevEl: slider.querySelector('.swiper-button-prev'),
		},
		on: {
			init: function (swiper) {
				const activeSlideVideo = $slides.eq(swiper.realIndex).find('video');

				videoControl(swiper);
			},
			slideChange: function (swiper) {
				$thumbs.eq(swiper.realIndex).addClass('is-active').siblings().removeClass('is-active');

				videoControl(swiper);
			},
		}
	});

	$thumbs.on('click', function(){
		productsSwiper.slideTo($(this).index());
	})

	function videoControl(swiper) {
		const activeSlideVideo = $slides.eq(swiper.realIndex).find('video');

		if ($allVideos.length) {
			$allVideos.each(function(){
				$(this).parent().removeClass('is-playing')
				$(this)[0].pause();
			})
		}

		if (activeSlideVideo.length) {
			activeSlideVideo.parent().addClass('is-playing')
			activeSlideVideo[0].play();
		}
	}
});