/**
 * External dependencies
 */
import { useRef } from 'react';
import Moveable from 'react-moveable';

/**
 * Internal dependencies
 */
import { updateCrystal, selectCrystal } from '@jewellery-configurator/store/actions';
import { useCrystals, useSelectedCrystal } from '@jewellery-configurator/store/selectors';

const Gesturable = (props) => {
    const { crystalId, targetRef, children } = props;

    const moveableRef = useRef();

    const crystals = useCrystals();
    const selectedCrystalId = useSelectedCrystal();
    const isSelected = crystalId === selectedCrystalId;

    return (
        <div className="jc-canvas-gesturable">
            {props.children}

            <Moveable
                ref={moveableRef}
                target={targetRef}
                draggable
                origin={false}
                rotatable={isSelected}
                snappable={true}
                rotationPosition="top"
                hideDefaultLines
                useResizeObserver
                useMutationObserver
                // useAccuratePosition
                bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: 'css' }}
                snapDirections={{ top: true, left: true, bottom: true, right: true, center: true, middle: true }}
                maxSnapElementGuidelineDistance={null}
                elementSnapDirections={{ top: true, left: true, bottom: true, right: true, center: true, middle: true }}
                elementGuidelines={crystals
                    .filter((crystal) => crystal.id !== crystalId)
                    .map((crystal) => `.js-canvas__crystal-${crystal.id}`)}
                snapContainer=".js-canvas"
                onDrag={(event) => {
                    event.target.style.transform = event.transform;
                }}
                onDragStart={() => {
                    if (!isSelected) {
                        selectCrystal(crystalId);
                    }
                }}
                onDragEnd={(event) => {
                    if (!event.isDrag) {
                        return;
                    }

                    updateCrystal(crystalId, {
                        top: event.lastEvent.translate[1],
                        left: event.lastEvent.translate[0],
                    });
                }}
                onRotate={(event) => {
                    event.target.style.cssText += event.cssText;
                }}
                onRotateEnd={(event) => {
                    updateCrystal(crystalId, {
                        rotation: event.lastEvent.rotation,
                    });
                }}
            />
        </div>
    );
};

export default Gesturable;
