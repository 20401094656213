/**
 * Internal dependencies
 */
import Configurator from '@jewellery-configurator/components/configurator/configurator';

const ScreenSetup = () => {
    return (
        <div className="jc-screen-setup">
            <Configurator />
        </div>
    );
};

export default ScreenSetup;
