import { $win, $body } from '../utils/globals'

$('.js-show-search').on('click', function(e){
	$body.toggleClass('has-search-opened');
});

$('.search-box').on('click', function(e){
	e.stopPropagation();
});

$body.on('click', function(e){
	$body.removeClass('has-search-opened');
});