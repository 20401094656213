let vh = window.innerHeight * 0.01;
let wait = null;

document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('orientationchange', resizeViewportVar);
window.addEventListener('resize', resizeViewportVar);

function resizeViewportVar() {
	clearTimeout(wait);

	wait = setTimeout(() => {
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, 50);
}
