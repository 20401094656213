/**
 * External dependencies
 */
import { useState } from 'react';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import ConfigGroup from '@jewellery-configurator/components/config-group/config-group';
import IconElements from '@jewellery-configurator/icons/elements';
import { selectElement } from '@jewellery-configurator/store/actions';
import { useElement } from '@jewellery-configurator/store/selectors';

const ConfigJewelleryElements = (props) => {
    const { options, isOpened, close, toggle } = props;

    const element = useElement();

    return (
        <ConfigGroup
            label={!!element ? `Елементи: ${element.label}` : 'Елементи'}
            icon={IconElements}
            size="auto"
            isOpened={isOpened}
            onToggle={toggle}
        >
            <div className="jc-jewellery-types per-row--3">
                {options.map((option, index) => {
                    return (
                        <div
                            className={classNames('jc-jewellery-type jc-jewellery-type--plain', element?.label === option.label && 'selected')}
                            key={index}
                            onClick={() => {
                                selectElement(option);
                                close();
                            }}
                        >
                            <div className="jc-jewellery-type__image">
                                <img src={option.thumbnail} />
                            </div>

                            <div className="jc-jewellery-type__label">{option.label}</div>
                        </div>
                    );
                })}
            </div>
        </ConfigGroup>
    );
};

export default ConfigJewelleryElements;
