const ScreenLoading = () => {
    return (
        <div className="jc-intro jc-screen jc-screen-loading">
            <a
                href="/"
                className="logo"
                style={{ backgroundImage: 'url(/wp-content/themes/kdp/resources/images/logo.svg)' }}
            />

            <div className="jc-intro__inner jc-screen__entry">
                <h2>Моля, изчакайте...</h2>

                <p>Изработваме Вашето бижу, за да Ви направим щастливи...</p>

                <div className="jc-loading-images">
                    <img
                        src="/wp-content/themes/kdp/resources/images/loading-image.gif"
                        className="jc-loading-image--small"
                    />

                    <img
                        src="/wp-content/themes/kdp/resources/images/loading-image.gif"
                        className="jc-loading-image--large"
                    />

                    <img
                        src="/wp-content/themes/kdp/resources/images/loading-image.gif"
                        className="jc-loading-image--small"
                    />
                </div>
            </div>
        </div>
    );
};

export default ScreenLoading;
