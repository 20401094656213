/**
 * Internal dependencies
 */
import ScreenIntro from '@jewellery-configurator/screen/screen-intro';
import ScreenSetup from '@jewellery-configurator/screen/screen-setup';
import ScreenComplete from '@jewellery-configurator/screen/screen-complete';
import { useScreen } from '@jewellery-configurator/store/selectors';

const Root = () => {
    const screen = useScreen();

    return (
        <div className="jc">
            {screen === 'intro' && <ScreenIntro />}

            {screen === 'setup' && <ScreenSetup />}

            {screen === 'complete' && <ScreenComplete />}
        </div>
    );
};

export default Root;
