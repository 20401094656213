	var activeAccordionClass = 'is-expanded';

	$('.accordion .accordion__head').on('click', function() {
		if ($(this).parent().hasClass(activeAccordionClass)) {
			$(this).next().slideUp().parent().removeClass(activeAccordionClass);
		}else {
			$(this)
			.next()
			.slideToggle()
			.parent()
			.addClass(activeAccordionClass)
				.siblings()
				.removeClass(activeAccordionClass)
				.children('.accordion__body')
				.slideUp();
		}
	});
