$(document).on(
  'found_variation.wc-variation-form',
  'form.variations_form',
  function (_, variation) {
    const $variationImage = $(
      '.slider-gallery .slider__slide img[src="' + variation.image.url + '"]',
    );

    if (!$variationImage.length) {
      return;
    }

    const slideIndex = $(
      '.slider-gallery.js-slider-gallery .slider__slide',
    ).index($variationImage.closest('.slider__slide'));

    if (slideIndex) {
      $('.slider-gallery.js-slider-gallery .swiper')[0].swiper.slideTo(
        slideIndex,
      );
    }
  },
);
