/**
 * External dependencies
 */
import { useEffect } from 'react';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import ConfigGroup from '@jewellery-configurator/components/config-group/config-group';
import IconJewelleryType from '@jewellery-configurator/icons/jewellery-type';
import { selectProductType } from '@jewellery-configurator/store/actions';
import { useProductType } from '@jewellery-configurator/store/selectors';

const ConfigJewelleryProductType = (props) => {
    const { options, isOpened, open, close, toggle } = props;

    const selectedProductType = useProductType();

    // useEffect(() => {
    //     let firstNonDraftOption;

    //     if (firstNonDraftOption = options.find(option => option.status !== 'draft')) {
    //         selectProductType(firstNonDraftOption);
    //     }
    // }, []);

    return (
        <ConfigGroup
            label={!!selectedProductType ? `Вид бижу: ${selectedProductType.title}` : 'Вид бижу'}
            icon={IconJewelleryType}
            size="auto"
            isOpened={isOpened}
            onToggle={toggle}
        >
            <div className="jc-jewellery-types">
                {options.map((option) => {
                    return (
                        <div
                            className={classNames(
                                'jc-jewellery-type jc-jewellery-type--plain',
                                option.status === 'draft' && 'unavailable',
                                selectedProductType?.id === option.id && 'selected'
                            )}
                            key={option.id}
                            onClick={() => {
                                if (option.status !== 'draft') {
                                    selectProductType(option);
                                    close();
                                }
                            }}
                        >
                            <div className="jc-jewellery-type__image">
                                <img src={option.thumbnail} />
                            </div>

                            <div className="jc-jewellery-type__label">{option.title}</div>
                        </div>
                    );
                })}
            </div>
        </ConfigGroup>
    );
};

export default ConfigJewelleryProductType;
