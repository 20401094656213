/**
 * External dependencies
 */
import classNames from "classnames";

/**
 * Internal dependencies
 */

const ConfigGroup = (props) => {
    const {
        icon: IconComponent,
        label,
        size = "default",
        children,
        isOpened,
        plain,
        onToggle,
    } = props;

    return (
        <div
            className={classNames(
                "jc-config-group",
                isOpened && "opened",
                plain && "plain",
                `size--${size}`
            )}
        >
            <div className="jc-config-group__head">
                {!plain && (
                    <div className="jc-config-group__head-icon">
                        <IconComponent />
                    </div>
                )}

                <span>{label}</span>

                {!plain && (
                    <button onClick={onToggle}>
                        {isOpened ? "Скрий" : "Добави"}
                    </button>
                )}
            </div>

            <div className="jc-config-group__body">{children}</div>
        </div>
    );
};

export default ConfigGroup;
