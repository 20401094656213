/**
 * External dependencies
 */
import { useCallback, useState } from 'react';

/**
 * The useToggle hook is used to toggle a boolean value. True/false.
 *
 * @param initial
 * @return {Object}
 */
export default function useToggle(initial = false) {
    const [on, handleSetOn] = useState(initial);

    const set = useCallback((payload) => {
        handleSetOn(payload);
    }, []);

    const setOn = useCallback(() => {
        handleSetOn(true);
    }, []);

    const setOff = useCallback(() => {
        handleSetOn(false);
    }, []);

    const toggle = useCallback(() => {
        handleSetOn((old) => !old);
    }, []);

    const reset = useCallback(() => {
        handleSetOn(initial);
    }, []);

    return {
        on,
        set,
        setOn,
        setOff,
        toggle,
        reset,
    };
}
