import { $win, $body } from '../utils/globals'

let winScrollTop = $win.scrollTop();

$win.on('load resize', function(){
	winScrollTop = $win.scrollTop();
})
.on('load scroll', () => {
	winScrollTop = $win.scrollTop();
	$body.toggleClass('has-fixed-header', winScrollTop > 100);
});
