/**
 * Internal dependencies
 */
import { useImage } from '@jewellery-configurator/store/selectors';

const ScreenComplete = () => {
    const image = useImage();

    return (
        <div className="jc-intro jc-screen">
            <div className="jc-intro__inner jc-screen__entry">
                <h2>Вашето бижу е изработено!</h2>

                <p>Вие успешно създадохте Вашето персонализирано бижу!</p>
                <p>Може да видите Вашата поръчка в количката Ви!</p>
                <p>Продължите да създавате бижута или може да разгледате още от нашите предложения!</p>

                <figure>
                    <img src={image} />
                </figure>

                <div className="jc-btn-group">
                    <a href="/" className="btn">
                        Към сайта
                    </a>

                    <a href="/jewellery-configurator" className="btn">
                        Направи друго бижу
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ScreenComplete;
